import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import { string, object, number, date, boolean, array } from "yup";
import _ from "lodash";
import NewRideActions from "reducers/ride/new-ride";
import NewSerieActions from "reducers/serie/new-serie";
import MinioActions from "reducers/minio";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import "react-dropzone-uploader/dist/styles.css";
// import Dropzone from "react-dropzone-uploader";
import datepickerLocal from "globals/local-dates";
import SuccessModalDialog from "./Success-Modal-Dialog";

import InputAddressField from "components/Input-Address-Field";

import upload from "globals/upload";

// import formIluIcon from "assets/img/form_ilu.svg";
import calendarIcon from "assets/img/calendar.svg";
import infoBullIcon from "assets/img/info-bull.svg";
// import AllerRetourIcon from "assets/img/allerretour";
// import CloseIcon from "assets/img/close";
import manageFilesIcon from "assets/img/manage-files.svg";
import ManageFilesNewRideModal from "components/Modal/ManageFilesNewRide";
import MyButton from "components/MyButton";
import { USER_ROLE } from "globals/constants";
import { Checkbox } from "react-bootstrap";

const VEHICLE_TYPES = {
  VSL: "VSL",
  AMBULANCE: "AMBULANCE",
  TAXI: "TAXI",
  MEDECIN: "MEDECIN",
};

class NewRide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSuccessAlert: false,
      showMore: false,
      showManageFilesModal: false,
      files: [],
    };
    this.bucket = "rides/files";
    this.bucketSerie = "series/files";

    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.initialValues = this.initialValues.bind(this);
    console.log(this.props);
  }

  initialValues() {
    const { service } = this.props.service;
    let pickup = {
      latitude: "",
      longitude: "",
      address: "",
    };

    if (
      service &&
      service.address &&
      service.address.address &&
      service.address.latitude &&
      service.address.longitude
    ) {
      pickup = {
        latitude: service.address.latitude,
        longitude: service.address.longitude,
        address: service.address.address,
      };
    }

    return {
      course: {
        dropoff: {
          latitude: "",
          longitude: "",
          address: "",
        },
        pickup,
        bookingNow: true,
        vehicleType: VEHICLE_TYPES.AMBULANCE,
        datetime: new Date(),
        note: "",
        isRoundTrip: false,
        isSimultaneous: true,
      },
      patient: {
        lastname: "",
        phone: "",
      },
      serie: {
        isSerie: false,
        nbDates: "",
      },
      files: [],
      doctorId: "",
    };
  }

  onSubmitForm(values, { resetForm, setSubmitting }) {
    const { createNewRideRequest, createNewSerieRequest, account } = this.props;
    const { files } = this.state;
    const { patient, course, serie, doctorId } = values;
    const { datetime, bookingNow, ...rest } = course;
    const _course = {
      ...rest,
      startNow: bookingNow,
      datetime: datetime.toISOString(),
    };

    const callback = async (error, filesInfo) => {
      if (error) {
        //handle error
        return;
      }
      setSubmitting(false);
      if (!_.isEmpty(filesInfo)) {
        // get presigned urls list and upload files to minio server
        this.props.putPresignedUrl(
          filesInfo.map((f) => f.path),
          (urls) => {
            for (let i = 0; i < urls.length; i++) {
              const url = urls[i];
              const file = files.find((e) => filesInfo[i].name === e.name);
              upload({ url, file });
            }
          }
        );
      }
      // resetForm();

      this.setState(
        Object.assign({}, this.state, { showSuccessAlert: true }),
        () => {
          setTimeout(() => {
            this.setState({
              showSuccessAlert: false,
              files: [],
            });
            resetForm({ values: this.initialValues() });
            //refresh the page
            // window.location.reload()
          }, 2000);
        }
      );
    };

    const docs = files.map(({ name, size }) => {
      const nameSplited = name.split(".");
      const extension = nameSplited.pop();
      return {
        name,
        size,
        extension,
        path: serie.isSerie ? this.bucketSerie : this.bucket,
      };
    });

    if (account.user.userType === USER_ROLE.SECRETARY) {
      if (!doctorId) {
        alert("Veuillez choisir un médecin");
        return;
      }
    }

    if (serie.isSerie) {
      createNewSerieRequest(patient, _course, serie, docs, doctorId, callback);
    } else {
      createNewRideRequest(patient, _course, docs, doctorId, callback);
    }
  }

  renderSelectDoctor(props) {
    if (this.props.account.user.userType !== USER_ROLE.SECRETARY) return;

    const { values, setFieldValue, errors, touched } = props;

    if (this.props.service.service.doctors.length == 0) {
      return (
        <Row>
          <div className="alert alert-danger" role="alert">
            Vous ne pouvez pas encore créer une demande de transport car vous
            n'avez pas de médecin affilié à votre compte
          </div>
        </Row>
      );
    }

    return (
      <Row>
        <select
          placeholder="Choisir un médecin"
          value={values.doctorId}
          onChange={(e) => setFieldValue("doctorId", e.target.value)}
          className={
            _.has(errors, "doctorId") && _.has(touched, "doctorId")
              ? "border-danger"
              : ""
          }
        >
          <option value="">Choisir un médecin</option>
          {this.props.service.service.doctors.map((doctor) => (
            <option value={doctor.id}>
              Dr. {doctor.lastname} {doctor.firstname}
            </option>
          ))}
        </select>
      </Row>
    );
  }

  renderStartAt(props) {
    const { values, setFieldValue, errors, touched } = props;
    const { bookingNow, datetime } = values.course;
    const currentDate = new DateObject();

    function CustomCalendarImput({ openCalendar, value, handleValueChange }) {
      return (
        <Form.Group as={Row} className={`mt-0 ${bookingNow && "d-none"}`}>
          <Col
            className="d-flex justify-content-center col-3"
            onClick={openCalendar}
          >
            <img src={calendarIcon} />
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Date de départ"
              value={value}
              onFocus={openCalendar}
              onChange={handleValueChange}
              className={
                _.has(errors, "course.datetime") &&
                _.has(touched, "course.datetime")
                  ? "border-danger"
                  : ""
              }
            />
          </Col>
        </Form.Group>
      );
    }
    return (
      <Row>
        <Col
          style={{ backgroundColor: "#F0F0F0" }}
          className="rounded d-flex justify-content-between p-1 col-8"
        >
          <MyButton
            isActive={bookingNow}
            onClick={() => {
              setFieldValue("course.bookingNow", true);
              setFieldValue("course.datetime", new Date());
            }}
          >
            Maintenant
          </MyButton>

          <MyButton
            isActive={!bookingNow}
            onClick={() => setFieldValue("course.bookingNow", false)}
          >
            Plus tard
          </MyButton>
        </Col>
        {bookingNow && (
          <Col className="">
            <OverlayTrigger
              placement="bottom"
              overlay={(propsOverlay) => (
                <Tooltip {...propsOverlay}>
                  Prise en charge dans l'heure
                </Tooltip>
              )}
            >
              <img src={infoBullIcon} />
            </OverlayTrigger>
          </Col>
        )}
        <Col className="col-4 d-flex align-items-center">
          <DatePicker
            format="DD/MM/YYYY HH:mm"
            value={datetime}
            locale={datepickerLocal}
            minDate={currentDate}
            weekStartDayIndex={1}
            disableDayPicker={false}
            showOtherDays={false}
            shadow={false}
            plugins={[<TimePicker position="bottom" hideSeconds />]}
            render={<CustomCalendarImput />}
            onChange={(selectedDate) => {
              setFieldValue("course.datetime", selectedDate?.toDate?.());
            }}
          />
        </Col>
      </Row>
    );
  }

  renderRideType(props) {
    const { values, setFieldValue, errors, touched } = props;
    const { isSerie, nbDates } = values.serie;

    return (
      <Row>
        <Col
          style={{ backgroundColor: "#F0F0F0" }}
          className="rounded d-flex justify-content-between p-1 col col-sm-8"
        >
          <MyButton
            isActive={!isSerie && !values.course.isRoundTrip}
            onClick={() => {
              setFieldValue("serie.isSerie", false);
              setFieldValue("course.isRoundTrip", false);
            }}
          >
            Simple
          </MyButton>
          <MyButton
            isActive={values.course.isRoundTrip}
            onClick={() => {
              setFieldValue("serie.isSerie", false);
              setFieldValue("course.isRoundTrip", true);
            }}
          >
            Aller/Retour
          </MyButton>
          <MyButton
            isActive={isSerie}
            onClick={() => {
              if (values.course.vehicleType == VEHICLE_TYPES.TAXI) {
                setFieldValue("course.vehicleType", VEHICLE_TYPES.AMBULANCE);
              }
              setFieldValue("course.isRoundTrip", false);
              setFieldValue("serie.isSerie", true);
            }}
          >
            Série
          </MyButton>
        </Col>
        <Col className="col col-sm-4">
          <Form.Group as={Row} className={`mt-0 ${!isSerie && "d-none"}`}>
            <Col
              className="d-flex justify-content-center align-items-center position-relative"
              style={{ color: "#999C9F", fontSize: 15 }}
            >
              Nombres de soins :
            </Col>

            <Col className="position-relative">
              <OverlayTrigger
                placement="bottom"
                overlay={(propsOverlay) => (
                  <Tooltip {...propsOverlay}>
                    Saisir le nombre de soins préscrit par le medecin
                  </Tooltip>
                )}
              >
                <img
                  style={{
                    position: "absolute",
                    top: -10,
                    right: -10,
                  }}
                  src={infoBullIcon}
                />
              </OverlayTrigger>
              <Form.Control
                type="text"
                placeholder="0"
                value={nbDates}
                onChange={(e) => setFieldValue("serie.nbDates", e.target.value)}
                className={
                  _.has(errors, "serie.nbDates") &&
                  _.has(touched, "serie.nbDates")
                    ? "border-danger"
                    : ""
                }
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    );
  }

  render() {
    const { t, i18n, settings } = this.props;
    const {
      isSubmitting = false,
      values = {},
      setFieldValue,
    } = this.formikRef || {};
    const { pickupFromService = false } = settings;

    return (
      <LoadingOverlay
        active={false}
        spinner
        className="h-100"
        styles={{
          spinner: (base) => ({
            ...base,
            width: "100px",
            "& svg circle": {
              stroke: "#0096AC",
            },
          }),
        }}
      >
        <div className="h-100 p-4">
          {/* <div className='d-none d-xl-flex'>
            <img src={formIluIcon} style={{ maxWidth: '450px' }} />
          </div> */}

          <div className="d-flex align-items-center justify-content-center h-100 w-100">
            <Card body border="#E5E5E5" className="shadow-none border pb-5">
              <h1 className="text-center mb-5">
                {t("newRideForm.title", { lng: i18n.language })}
              </h1>

              <Formik
                innerRef={(p) => (this.formikRef = p)}
                enableReinitialize={true}
                initialValues={
                  this.formikRef == null ? this.initialValues() : values
                }
                onSubmit={this.onSubmitForm}
                validationSchema={validationSchema}
              >
                {(props) => {
                  const { setFieldValue, errors, touched } = props;
                  return (
                    <Form
                      className="d-flex flex-column gap"
                      style={{ gap: "20px" }}
                    >
                      {this.renderSelectDoctor(props)}

                      {this.renderStartAt(props)}

                      {this.renderRideType(props)}
                      {/* <Input
                        className={
                          _.has(errors, "course.pickup") &&
                          _.has(touched, "course.pickup")
                            ? "error"
                            : ""
                        }
                        formikProps={props}
                        formikPropsPath="course.pickup"
                        label={t("newRideForm.ride.pickup_address_label", {
                          lng: i18n.language,
                        })}
                        onAddressChanged={this.onAddressChanged}
                      /> */}

                      <Form.Group as={Row}>
                        <Form.Label column sm="5" style={{ color: "#999C9F" }}>
                          Choisir un véhicule :
                        </Form.Label>
                        <Col className="d-flex col-7" style={{ gap: "1em" }}>
                          <MyButton
                            isVehicle
                            variant="vehicletype"
                            isActive={
                              props.values.course.vehicleType ===
                              VEHICLE_TYPES.AMBULANCE
                            }
                            onClick={() =>
                              setFieldValue(
                                "course.vehicleType",
                                VEHICLE_TYPES.AMBULANCE
                              )
                            }
                          >
                            {t(
                              `Common:vehicle.categories.${VEHICLE_TYPES.AMBULANCE}`
                            )}
                          </MyButton>
                          <MyButton
                            isVehicle
                            variant="vehicletype"
                            isActive={
                              props.values.course.vehicleType ===
                              VEHICLE_TYPES.VSL
                            }
                            onClick={() =>
                              setFieldValue(
                                "course.vehicleType",
                                VEHICLE_TYPES.VSL
                              )
                            }
                          >
                            {t(
                              `Common:vehicle.categories.${VEHICLE_TYPES.VSL}`
                            )}
                          </MyButton>
                          {/* <MyButton
                            isVehicle
                            variant="vehicletype"
                            isActive={props.values.course.vehicleType === VEHICLE_TYPES.MEDECIN}
                            onClick={() => setFieldValue("course.vehicleType", VEHICLE_TYPES.MEDECIN)}
                          >
                            {t(`Common:vehicle.categories.${VEHICLE_TYPES.MEDECIN}`)}
                          </MyButton> */}
                          <MyButton
                            isVehicle
                            variant="vehicletype"
                            isActive={
                              props.values.course.vehicleType ===
                              VEHICLE_TYPES.TAXI
                            }
                            onClick={() =>
                              setFieldValue(
                                "course.vehicleType",
                                VEHICLE_TYPES.TAXI
                              )
                            }
                            disabled={true}
                          >
                            {t(
                              `Common:vehicle.categories.${VEHICLE_TYPES.TAXI}`
                            )}
                          </MyButton>
                        </Col>
                      </Form.Group>
                      {!pickupFromService && (
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            sm="5"
                            style={{ color: "#999C9F" }}
                          >
                            Renseigner le départ :
                          </Form.Label>
                          <Col sm="7">
                            <InputAddressField
                              className={
                                _.has(errors, "course.pickup") &&
                                _.has(touched, "course.pickup")
                                  ? "error"
                                  : ""
                              }
                              formikProps={props}
                              formikPropsPath="course.pickup"
                              label={t(
                                "newRideForm.ride.pickup_address_label",
                                {
                                  lng: i18n.language,
                                }
                              )}
                            />
                          </Col>
                        </Form.Group>
                      )}
                      <Form.Group as={Row}>
                        <Form.Label column sm="5" style={{ color: "#999C9F" }}>
                          Renseigner la destination :
                        </Form.Label>
                        <Col sm="7">
                          <InputAddressField
                            className={
                              _.has(errors, "course.dropoff") &&
                              _.has(touched, "course.dropoff")
                                ? "error"
                                : ""
                            }
                            formikProps={props}
                            formikPropsPath="course.dropoff"
                            label={t("newRideForm.ride.dropoff_address_label", {
                              lng: i18n.language,
                            })}
                            // onAddressChanged={() => console.log("address changed", props.course.dropoff)}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="5" style={{ color: "#999C9F" }}>
                          Renseigner le nom du patient :
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            placeholder="Nom du patient"
                            value={props.values.patient.lastname}
                            onChange={(e) =>
                              setFieldValue("patient.lastname", e.target.value)
                            }
                            className={
                              _.has(errors, "patient.lastname") &&
                              _.has(touched, "patient.lastname")
                                ? "border-danger"
                                : ""
                            }
                          />
                        </Col>
                        <Col sm="3" className="position-relative">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={(propsOverlay) => (
                              <Tooltip {...propsOverlay}>
                                Permet au patient de suivre
                                {props.values.serie.isSerie
                                  ? " la série et de remplir les dates de soins."
                                  : " le transport."}
                              </Tooltip>
                            )}
                          >
                            <img
                              style={{
                                position: "absolute",
                                top: -10,
                                right: -10,
                              }}
                              src={infoBullIcon}
                            />
                          </OverlayTrigger>
                          <div className="d-block d-md-none">&nbsp;</div>
                          <Form.Control
                            type="text"
                            placeholder="Téléphone"
                            value={props.values.patient.phone}
                            onChange={(e) =>
                              setFieldValue("patient.phone", e.target.value)
                            }
                            className={
                              _.has(errors, "patient.phone") &&
                              _.has(touched, "patient.phone")
                                ? "border-danger"
                                : ""
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Col>
                        <MyButton
                          // isActive={this.state.showMore}
                          onClick={() =>
                            this.setState({ showMore: !this.state.showMore })
                          }
                        >
                          {this.state.showMore ? "Voir moins" : "Voir plus"}
                        </MyButton>

                        {this.state.showMore && (
                          <div>
                            <Form.Check
                              id="isSimultaneous"
                              type="checkbox"
                              label="Simultané"
                              checked={props.values.course.isSimultaneous}
                              onChange={(e) => setFieldValue("course.isSimultaneous", e.target.checked)}
                            />

                            <Form.Group as={Row} className="mb-4">
                              <div className="col-md-6">
                                <Form.Label
                                  column
                                  sm="5"
                                  style={{ color: "#999C9F" }}
                                >
                                  Note :
                                </Form.Label>
                                <textarea
                                  className="form-control"
                                  rows="2"
                                  value={props.values.course.note}
                                  onChange={(e) =>
                                    setFieldValue("course.note", e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <Form.Label
                                  column
                                  sm="5"
                                  style={{ color: "#999C9F" }}
                                >
                                  Fichiers :
                                </Form.Label>

                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-wrap">
                                    {this.state.files.map((file, index) => (
                                      <div
                                        className="mr-2 mb-2 d-flex justify-content-between"
                                        style={{ maxWidth: "250px" }}
                                        key={index}
                                      >
                                        <div className="text-truncate">
                                          {file.name}
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                  <div className="d-flex flex-column align-items-center">
                                    <img
                                      src={manageFilesIcon}
                                      alt="manage files"
                                      style={{
                                        width: "30px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          showManageFilesModal: true,
                                        })
                                      }
                                    />
                                    <div style={{ color: "#0096ac" }}>
                                      {this.state.files.length > 0
                                        ? "Gérer"
                                        : "Ajouter"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        )}
                      </Col>

                      <Button
                        onClick={() => props.handleSubmit()}
                        variant="reservation"
                      >
                        {t("newRideForm.submit_booking_button", {
                          lng: i18n.language,
                        })}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </Card>
          </div>
        </div>
        <SuccessModalDialog show={this.state.showSuccessAlert} />

        <ManageFilesNewRideModal
          files={this.state.files}
          show={this.state.showManageFilesModal}
          onChangeFiles={(files) => this.setState({ files })}
          onCancel={() => this.setState({ showManageFilesModal: false })}
        />
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.asMutable({ deep: true }),
    action: state.newRide.action.asMutable({ deep: true }),
    service: state.service.asMutable({ deep: true }),
    settings: state.settings.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewRideRequest: (...args) =>
      dispatch(NewRideActions.createNewRideRequest(...args)),
    createNewSerieRequest: (...args) =>
      dispatch(NewSerieActions.createNewSerieRequest(...args)),
    putPresignedUrl: (...args) =>
      dispatch(MinioActions.putPresignedUrl(...args)),
  };
};

const newRideRedux = connect(mapStateToProps, mapDispatchToProps)(NewRide);
export default withTranslation(["Private", "Common"], { wait: true })(
  newRideRedux
);

// VALIDATION SCHEMA

const validationSchema = object().shape({
  patient: object().shape({
    lastname: string().required("Common:Errors.lastname_required"),
    phone: string(),
  }),
  course: object().shape({
    vehicleType: string()
      .oneOf(
        Object.values(VEHICLE_TYPES),
        "Common:Errors.vehicle_type_required"
      )
      .required("Common:Errors.vehicle_type_required"),
    bookingNow: boolean(),
    datetime: date().when("bookingNow", {
      is: false,
      then: date().required("Common:Errors.booking_date_required"),
    }),
    dropoff: object().shape({
      latitude: number().required(""),
      longitude: number().required(""),
      address: string().required("Common:Errors.dropoff_address_required"),
    }),
    pickup: object().shape({
      latitude: number(),
      longitude: number(),
      address: string(),
    }),
    note: string(),
    isRoundTrip: boolean(),
    isSimultaneous: boolean(),
  }),
  serie: object().shape({
    isSerie: boolean(),
    nbDates: number().when("isSerie", {
      is: true,
      then: number().required("Common:Errors.serie_dates_required"),
    }),
  }),
  files: array(),
  doctorId: string(),
});
