import { put, takeLatest } from "redux-saga/effects";
import RideHistoryActions, {
  RideHistoryTypes,
} from "reducers/ride/ride-history";
import errorHandler from "sagas/error-handler";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* rideHistoryAsyncRequest({ page, limit, filter = false }) {
  let filterQuery = null;
  if (filter) {
    filterQuery = `
      {
        dates: [${filter.dates}],
        text: "${filter.text}"
      }
    `;
  }
  const query = `
    {
      ServiceRideHistory(page:${page}, limit:${limit}, filter:${filterQuery})
      {
        limit,
        page,
        totalDocs,
        totalPages,
        hasNextPage,
        rides {
          _id,
          bilan,
          createdAt,
          rideNumber,
          rideType,
          datetime,
          patient{ 
            firstname,
            lastname,
            nss,
            status,
            phone
          },
          transporter{
            company {name},
            vehicle { make, plate, phone, model, color, id, category }
          },
          taxi{
            driver{firstname, lastname},
            vehicle { make, plate, phone, model, color, id, category }
          }
          dropoff{ address },
          pickup{ address },
          subject,
          service{ staff },
          status,
          summary {
            estimated {
              duration { value text }
              distance { value text }
            }
            onway onboard arrived completed waiting
          }
        }
      }
    }
  `;

  try {
    const {ServiceRideHistory} = yield graphQLClient().request(query);

    yield put(RideHistoryActions.rideHistoryRequestSuccess(ServiceRideHistory));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, RideHistoryActions.rideHistoryRequestFailure);
  }
}

function* rideDetailsAsyncRequest({ rideId }) {
  const query = `
      {
  
        ServiceGetRideById(rideId:"${rideId}")
        { 
          _id 
           rideType
           Taxi
           patient{firstname lastname phone}
          pickup{address}
          dropoff{address}
          vehicleCategory
          vehicleOptions
          subject
          transporter{company{id name}
          driver{firstname lastname}
          vehicle{make model category }
          }
        }
       }          
      
  `;

  try {
    const data = yield graphQLClient().request(query);
    const ride = data.ServiceGetRideById;

    yield put(RideHistoryActions.rideDetailsRequestSuccess(ride));
    if (ride != null && ride.transporter != null) {
      yield put(
        RideHistoryActions.TransporterBlackListedRequest(
          ride.transporter.company.id
        )
      );
      yield put(
        RideHistoryActions.TransporterWhiteListedRequest(
          ride.transporter.company.id
        )
      );
    }
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, RideHistoryActions.rideDetailsRequestFailure);
  }
}

function* addToBlackListAsyncRequest({ transporterId, callback }) {
  const query = `
    mutation {
   

        ServiceAddTransporterToTheBlackList(tansporterId:"${transporterId}")
        
        
        
    }
  `;
  try {
    yield graphQLClient().request(query);

    yield put(RideHistoryActions.addToBlackListRequestSuccess());

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    if (_.isFunction(callback)) callback({ error: message });
    yield errorHandler(
      message,
      RideHistoryActions.addToBlackListRequestFailure
    );
  }
}

function* addToWhiteListAsyncRequest({ transporterId, callback }) {
  const query = `
    mutation {
   

      ServiceAddTransporterToTheWhiteList(tansporterId:"${transporterId}")
        
        
        
    }
  `;
  try {
    yield graphQLClient().request(query);

    yield put(RideHistoryActions.addToWhiteListRequestSuccess());

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    if (_.isFunction(callback)) callback({ error: message });
    yield errorHandler(
      message,
      RideHistoryActions.addToWhiteListRequestFailure
    );
  }
}

function* didBlackListedAsyncRequest({ transporterId, callback }) {
  const query = `
  query {
   

    ServiceIsTransporterBlackListed(tansporterId:"${transporterId}")

      
      
  }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const isBlackListed = data.ServiceIsTransporterBlackListed;
    yield put(
      RideHistoryActions.TransporterBlackListedRequestSuccess(isBlackListed)
    );

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    if (_.isFunction(callback)) callback({ error: message });
    yield errorHandler(
      message,
      RideHistoryActions.TransporterBlackListedRequestFailure
    );
  }
}

function* didWhiteListedAsyncRequest({ transporterId, callback }) {
  const query = `
    query {
   

      ServiceIsTransporterWhiteListed(tansporterId:"${transporterId}")
      
        
        
    }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const isWhiteListed = data.ServiceIsTransporterWhiteListed;
    yield put(
      RideHistoryActions.TransporterWhiteListedRequestSuccess(isWhiteListed)
    );

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    if (_.isFunction(callback)) callback({ error: message });
    yield errorHandler(
      message,
      RideHistoryActions.TransporterWhiteListedRequestFailure
    );
  }
}
/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(RideHistoryTypes.RIDE_HISTORY_REQUEST, rideHistoryAsyncRequest),
  takeLatest(RideHistoryTypes.RIDE_DETAILS_REQUEST, rideDetailsAsyncRequest),
  takeLatest(
    RideHistoryTypes.ADD_TO_BLACK_LIST_REQUEST,
    addToBlackListAsyncRequest
  ),
  takeLatest(
    RideHistoryTypes.ADD_TO_WHITE_LIST_REQUEST,
    addToWhiteListAsyncRequest
  ),
  takeLatest(
    RideHistoryTypes.TRANSPORTER_BLACK_LISTED_REQUEST,
    didBlackListedAsyncRequest
  ),
  takeLatest(
    RideHistoryTypes.TRANSPORTER_WHITE_LISTED_REQUEST,
    didWhiteListedAsyncRequest
  ),
];
