import { Component } from "react";
import moment from "moment";
import _ from "lodash";
import { getBackendUrl } from "functions";
import NotSpecified from "./NotSpecified";
import pdfIcon from "assets/img/pdf-svgrepo-com.svg";

function formatedDateRecap(d) {
  if (!d || !moment(d).isValid()) return "Non renseigné";
  return moment(d).format("HH:mm");
}

class RideHistoryRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMore: false,
    };
  }

  render() {
    const { t, i18n } = this.props;
    const {
      _id,
      rideNumber = "",
      patient = {},
      status = "",
      datetime = "",
      createdAt = "",
      taxi,
      transporter,
      rideType,
      dropoff = {},
      pickup = {},
      subject = "",
      summary,
    } = this.props.ride || {};
    let transporterInfo = "";
    let make = "";
    let model = "";
    let plate = "";
    let color = "";
    let phone = "";
    if (status != "incoming") {
      let obj = null;
      if (rideType === "taxiRide") {
        transporterInfo = `${_.get(taxi, "driver.lastname", "")} ${_.get(
          taxi,
          "driver.firstname",
          ""
        )}`;
        obj = taxi;
      } else {
        obj = transporter;
        transporterInfo = _.get(transporter, "company.name", "");
      }

      make = _.get(obj, "vehicle.make", "");
      model = _.get(obj, "vehicle.model", "");
      plate = _.get(obj, "vehicle.plate", "");
      color = _.get(obj, "vehicle.color", "");
      phone = _.get(obj, "vehicle.phone", "");
    }
    transporterInfo = _.capitalize(transporterInfo);

    let patientLname = patient.lastname || "";
    let patientFname = patient.firstname || "";

    patientLname = _.capitalize(patientLname);
    patientFname = _.capitalize(patientFname);

    return (
      <>
        <div
          className="d-flex d-none d-lg-flex"
          style={{
            border: "1px solid #999C9F",
            borderRadius: 4,
            padding: "5px 30px",
            // width: "986px",
            // height: "120px",
          }}
        >
          {/* COL 1 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100px",
              marginRight: "40px",
            }}
          >
            <div style={{ fontSize: 16, fontWeight: 900 }}>{rideNumber}</div>
            <div style={{ fontSize: 12, fontWeight: 500, marginTop: "16px" }}>
              {moment(datetime).format("DD MMM HH:mm")}
            </div>
          </div>

          {/* COL 2 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "140px",
              borderRight: "1px solid #999C9F",
              marginRight: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
              >
                Prise en charge
              </div>
              {pickup.address ? (
                <span
                  style={{ fontSize: "12px", fontWeight: 500 }}
                  className="text-truncate-2"
                >
                  {pickup.address || ""}
                </span>
              ) : (
                <NotSpecified />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between" /* marginTop: "4px" */,
              }}
            >
              <div
                style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
              >
                Dépose
              </div>
              {dropoff.address ? (
                <span
                  style={{ fontSize: "12px", fontWeight: 500 }}
                  className="text-truncate-2"
                >
                  {dropoff.address || ""}
                </span>
              ) : (
                <NotSpecified />
              )}
            </div>
          </div>

          {/* COL 3 */}
          <div
            style={{ display: "flex", flexDirection: "column", width: "132px" }}
          >
            <div
              style={{ fontSize: "16px", fontWeight: 900 }}
            >{`${patientFname} ${patientLname}`}</div>
            <div
              style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
            >
              Motif
            </div>
            {subject ? (
              <div style={{ fontSize: "12px", fontWeight: 900 }}>{subject}</div>
            ) : (
              <NotSpecified />
            )}
            <div
              style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
            >
              Numéro
            </div>
            {patient?.phone ? (
              <div style={{ fontSize: "12px", fontWeight: 900 }}>
                {patient?.phone}
              </div>
            ) : (
              <NotSpecified />
            )}
          </div>

          {/* COL5 */}
          <div
            style={{
              width: "132px",
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              marginRight: "40px",
            }}
          >
            <div
              style={{
                color: "#999C9F",
                fontSize: "12px",
                fontWeight: 500,
                marginBottom: "2px",
              }}
            >
              Véhicule
            </div>
            {plate === "" ? (
              <NotSpecified />
            ) : (
              <>
                <div style={{ fontSize: "12px", fontWeight: 900 }}>
                  {transporterInfo}
                </div>
                <div style={{ fontSize: "12px", fontWeight: 700 }}>{plate}</div>
                <div style={{ fontSize: "12px", fontWeight: 500 }}>{phone}</div>
              </>
            )}
          </div>

          {/* COL */}
          <div
            style={{ display: "flex", flexDirection: "column", width: "173px" }}
          >
            <div
              style={{ fontSize: "12px", fontWeight: 600, color: "#999C9F" }}
            >
              Récapitulatif du transport
            </div>
            <div style={{ display: "flex", gap: "15px" }}>
              <div>
                <div
                  style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
                >
                  Départ
                </div>
                <div style={{ fontSize: "12px", fontWeight: 600 }}>
                  {formatedDateRecap(summary?.onway)}
                </div>
              </div>
              <div>
                <div
                  style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
                >
                  Distance
                </div>
                <div style={{ fontSize: "12px", fontWeight: 600 }}>
                  {summary?.estimated?.distance?.text}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "15px" }}>
              <div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#999C9F",
                  }}
                >
                  Prise en charge
                </div>
                <div style={{ fontSize: "12px", fontWeight: 600 }}>
                  {formatedDateRecap(summary?.onboard)}
                </div>
              </div>
              <div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#999C9F",
                  }}
                >
                  Dépose
                </div>
                <div style={{ fontSize: "12px", fontWeight: 600 }}>
                  {formatedDateRecap(summary?.arrived)}
                </div>
              </div>
            </div>
          </div>

          {/* COL6 */}
          <div
            style={{ display: "flex", marginTop: "8px", marginLeft: "30px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "start",
              }}
            >
              <span className={`status-history ${status} text-center`}>
                <div className={`status-point status-${status}`} />
                {t(`Common:Ride.Status.${status}`, { lng: i18n.language })}
              </span>
            </div>
          </div>
        </div>

        {/* SMARTPHONE */}
        <div
          className="d-flex d-lg-none w-100"
          style={{
            flexDirection: "column",
            border: "1px solid #999C9F",
            borderRadius: 4,
            padding: "5px 30px",
          }}
          onClick={() => this.setState({ showMore: !this.state.showMore })}
        >
          <div className="d-flex justify-content-between">
            {/* COL 1 */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontSize: 16, fontWeight: 900 }}>{rideNumber}</div>
              <div style={{ fontSize: 12, fontWeight: 500, marginTop: "5px" }}>
                {moment(datetime).format("DD MMM HH:mm")}
              </div>
            </div>

            {/* PATIENT */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "132px",
              }}
            >
              <div
                style={{ fontSize: "14px", fontWeight: 900 }}
              >{`${patientFname} ${patientLname}`}</div>
              <div
                style={{ fontSize: "11px", fontWeight: 500, color: "#999C9F" }}
              >
                Motif
              </div>
              {subject ? (
                <div style={{ fontSize: "12px", fontWeight: 900 }}>
                  {subject}
                </div>
              ) : (
                <NotSpecified />
              )}
              <div
                style={{ fontSize: "11px", fontWeight: 500, color: "#999C9F" }}
              >
                Numéro
              </div>
              {patient?.phone ? (
                <div style={{ fontSize: "12px", fontWeight: 900 }}>
                  {patient?.phone}
                </div>
              ) : (
                <NotSpecified />
              )}
            </div>

            {/* STATUS */}
            <div
              style={{ display: "flex", marginTop: "8px", marginLeft: "30px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "start",
                }}
              >
                <span className={`status-history ${status} text-center small`}>
                  <div className={`status-point status-${status} small`} />
                  {t(`Common:Ride.Status.${status}`, { lng: i18n.language })}
                </span>
              </div>
            </div>
          </div>

          {/* SHOW MORE */}
          {this.state.showMore && (
            <div>
              <div className="mt-2 d-flex">
                {/* ADDRESS */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "140px",
                    borderRight: "1px solid #999C9F",
                    marginRight: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#999C9F",
                      }}
                    >
                      Prise en charge
                    </div>
                    {pickup.address ? (
                      <span
                        style={{ fontSize: "12px", fontWeight: 500 }}
                        className="text-truncate-2"
                      >
                        {pickup.address || ""}
                      </span>
                    ) : (
                      <NotSpecified />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between" /* marginTop: "4px" */,
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#999C9F",
                      }}
                    >
                      Dépose
                    </div>
                    {dropoff.address ? (
                      <span
                        style={{ fontSize: "12px", fontWeight: 500 }}
                        className="text-truncate-2"
                      >
                        {dropoff.address || ""}
                      </span>
                    ) : (
                      <NotSpecified />
                    )}
                  </div>
                </div>
                {/* TRANSPORTER + VOITURE */}
                <div
                  style={{
                    width: "132px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    marginRight: "40px",
                  }}
                >
                  <div
                    style={{
                      color: "#999C9F",
                      fontSize: "12px",
                      fontWeight: 500,
                      marginBottom: "2px",
                    }}
                  >
                    Véhicule
                  </div>
                  {plate === "" ? (
                    <NotSpecified />
                  ) : (
                    <>
                      <div style={{ fontSize: "12px", fontWeight: 900 }}>
                        {transporterInfo}
                      </div>
                      <div style={{ fontSize: "12px", fontWeight: 700 }}>
                        {plate}
                      </div>
                      <div style={{ fontSize: "12px", fontWeight: 500 }}>
                        {phone}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="mt-2 d-flex">
                {/* RECAPITULATIF */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "173px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      color: "#999C9F",
                    }}
                  >
                    Récapitulatif du transport
                  </div>
                  <div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#999C9F",
                      }}
                    >
                      Départ
                    </div>
                    <div style={{ fontSize: "12px", fontWeight: 600 }}>
                      {formatedDateRecap(summary?.onway)}
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: "15px" }}>
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#999C9F",
                        }}
                      >
                        Prise en charge
                      </div>
                      <div style={{ fontSize: "12px", fontWeight: 600 }}>
                        {formatedDateRecap(summary?.onboard)}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#999C9F",
                        }}
                      >
                        Dépose
                      </div>
                      <div style={{ fontSize: "12px", fontWeight: 600 }}>
                        {formatedDateRecap(summary?.arrived)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default RideHistoryRow;
