import React, { Component } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import googleMapStyle from "assets/googleMapStyle";
import resaamVehicle from "assets/img/AmbulanceMap.svg"; //  resaam-vehicle.svg'
import LieuDarrivee from "assets/img/Lieu-darrivee.svg";
import LieuDePriseEnCharge from "assets/img/Lieu-de-prise-en-charge.svg";
import { useEffect } from "react";

function MapView(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [direction, setDirection] = React.useState(null);
  const zoom = props?.isMultiple ? 12 : 13;

  const DEPART = {lat: 48.8308702, lng: 2.1252093, icon: LieuDePriseEnCharge }; // 177 Rue de Versailles
  const PATIENT_1 = {lat: 48.8032009, lng: 2.1555721 }; // 12 Rue de la Concorde
  const PATIENT_2 = {lat: 48.80322, lng: 2.1723664 }; // 2 Rue du Dr Roux
  const PATIENT_4 = {lat: 48.7839482, lng: 2.1684251 }; // 8 Av. de Savoie
  const MOCK_MULTIPLE = [DEPART, PATIENT_1, PATIENT_2, PATIENT_4];

  const center = props?.isMultiple ? DEPART : { lat: props.latitude, lng: props.longitude };

  /*const onLoad = React.useCallback(function callback (map) {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])*/

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const fitBounds = () => {
    let markers = [];
    if (props.from) markers.push(props.from);
    if (_.isNumber(props.to?.lat) && _.isNumber(props.to?.lng)) markers.push(props.to);
    if(props?.isMultiple) markers = MOCK_MULTIPLE;
    const bounds = new window.google.maps.LatLngBounds();
    markers.map((position) => {
      bounds.extend(position);
      return position;
    });
    map.fitBounds(bounds);
  };

  const onLoadMap = (map) => {
    setMap(map);
  };

  const getDirection = async () => {
    const directionService = new window.google.maps.DirectionsService();
    let res = null;
    // multiple destination SIMULTANEOUS
    if (props?.isMultiple) {

      const waypoints = [PATIENT_1, PATIENT_2].map(loc => ({location : loc}))

      res = await directionService.route({
        origin: DEPART,
        destination: PATIENT_4,
        waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
      });

    } else {
      res = await directionService.route({
        origin: new window.google.maps.LatLng(props.from),
        destination: new window.google.maps.LatLng(props.to),
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
    }

    // if(res.status === "OK") console.log("RES STATUS === 'OK'");
    setDirection(res);
  };

  useEffect(() => {
    if (map) {

      map.setZoom(zoom);
      fitBounds();
      if (props.from &&  (_.isNumber(props.to?.lat) && _.isNumber(props.to?.lng))) getDirection();
    }
  }, [props]);


  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ height: "100%", width: "100%" }}
      center={center}
      options={googleMapStyle}
      onLoad={onLoadMap}
      onUnmount={onUnmount}
      zoom={zoom}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {/*props.children*/}
      {!props?.isMultiple && props.from && (
        <Marker
          position={props.from}
          icon={{
            url: props.from.icon,
            //anchor: new google.maps.Point(5, 58)
          }}
        />
      )}

      {!props?.isMultiple && (_.isNumber(props.to?.lat) && _.isNumber(props.to?.lng)) && (
        <Marker
          position={props.to}
          icon={{
            url: props.to.icon,
            //anchor: new google.maps.Point(5, 58)
          }}
        />
      )}

      {props?.isMultiple && MOCK_MULTIPLE.map((loc, key) => (
        <Marker
          key={key}
          position={loc}
          icon={{ url: loc?.icon }}
        />
      ))}

      {direction && (
        <DirectionsRenderer
          directions={direction}
          options={{
            markerOptions: { icon: " ", anchorPoint: { x: 0.2, y: 0.2 } },
            polylineOptions: {
              zIndex: 10,
              strokeColor: "#9273FF",
              strokeWeight: 5,
            },
          }}
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}
class DriverLocationModalDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { coordinates = { longitude: null, latitude: null }, ride } =
      this.props;

    const latitude = coordinates.latitude || 48.856614;
    const longitude = coordinates.longitude || 2.3522219;
    let to = undefined;

    if (ride && _.includes(["onway", "pending", "incoming"], ride.status)) {
      to = {
        lat: ride.pickup.latitude,
        lng: ride.pickup.longitude,
        icon: LieuDePriseEnCharge,
      };
    } else if (ride) {
      to = {
        lat: ride.dropoff.latitude,
        lng: ride.dropoff.longitude,
        icon: LieuDarrivee,
      };
    }

    return (
      <Modal {...this.props} dialogClassName="modal-90h" centered>
        <Modal.Body className="text-center" style={{ width: 500, height: 500 }}>
          <div
            style={{
              position: "absolute",
              zIndex: 998,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <MapView
              latitude={latitude}
              longitude={longitude}
              from={{ lat: latitude, lng: longitude, icon: resaamVehicle }}
              to={to}
              isMultiple={ride?.isSimultaneous}
            ></MapView>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default DriverLocationModalDialog;
