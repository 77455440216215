import { put, takeLatest } from "redux-saga/effects";
import OngoingRidesActions, {
  OngoingRideTypes,
} from "reducers/ride/ongoing-rides";
import errorHandler from "sagas/error-handler";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

const rideQuery = `
  {
    _id,
    createdAt,
    rideNumber,
    datetime,
    subject,
    patient{ 
      firstname,
      lastname,
      nss,
      status,
      age
    },
    transporter {
      company { name, phones }
      vehicle { plate make model color phone }
    }
    taxi {
      driver { firstname, lastname }
      vehicle{plate make model color phone }
    }
    dropoff{address latitude longitude},
    pickup{address latitude longitude},
    service{staff},
    rideType,
    waitingTime { duration_in_traffic }
    status
    nextDispatchingAt
    files { path, name, extension, size }
    isSimultaneous
  }
`;
function* onGoingRidesAsyncRequest({ page, limit, filter = false }) {
  let filterQuery = null;
  if (filter) {
    filterQuery = `
      {
        dates: [${filter.dates}],
        text: "${filter.text}"
      }
    `;
  }
  const query = `
    {
      ServiceTransporterOngoingRides( 
        page:${page}, limit:${limit}, filter:${filterQuery}
      ) {
        limit,
        page,
        totalDocs,
        totalPages,
        hasNextPage,
        rides ${rideQuery}
      }
    }         
  `;

  try {
    const data = yield graphQLClient().request(query);
    const rides = data.ServiceTransporterOngoingRides;

    yield put(OngoingRidesActions.onGoingRidesRequestSuccess(rides));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, OngoingRidesActions.onGoingRidesRequestFailure);
  }
}

function* setPatientStatusAsyncRequest({ rideId, status, callback }) {
  const query = `
    mutation { 
      ServiceSetPatientNextStatus(rideId:"${rideId}", status: ${status}) {
        _id
        patient {
          status
        }
      }
    }
  `;

  try {
    const data = yield graphQLClient().request(query);
    yield put(
      OngoingRidesActions.setPatientStatusRequestSuccess(
        data.ServiceSetPatientNextStatus
      )
    );
    if (_.isFunction(callback)) callback({});
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    if (_.isFunction(callback)) callback({ error: message });

    yield errorHandler(
      message,
      OngoingRidesActions.setPatientStatusRequestFailure
    );
  }
}

//______________________ Company Service __________________
function* cancelRideAsyncRequest({ rideId, callback }) {
  //
  const query = `
    mutation { 
      ServiceCancelRide(rideId:"${rideId}") {
        _id
      }
    }
  `;

  try {
    const data = yield graphQLClient().request(query);
    yield put(
      OngoingRidesActions.cancelRideRequestSuccess(data.ServiceCancelRide)
    );
    if (_.isFunction(callback)) callback({});
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    if (_.isFunction(callback)) callback({ error: message });

    yield errorHandler(message, OngoingRidesActions.cancelRideRequestFailure);
  }
}

function* onRefreshDispatchRideAsyncRequest({ rideId, callback }) {
  const query = `
    mutation { 
      ServiceRefreshDispatchRide(rideId:"${rideId}") ${rideQuery}
    }
  `;

  try {
    const { ServiceRefreshDispatchRide } = yield graphQLClient().request(query);
    yield put(
      OngoingRidesActions.onRefreshDispatchRideSuccess(
        ServiceRefreshDispatchRide
      )
    );
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    if (_.isFunction(callback)) callback({ error: message });
    yield errorHandler(
      message,
      OngoingRidesActions.onRefreshDispatchRideFailure
    );
  }
}

function* onAddFilesToRideAsyncRequest({ rideId, files = [], callback }) {
  const filesFormated = files.reduce((acc, file) => {
    acc += `{name: "${file.name}", size: "${file.size}", path: "${file.path}", extension: "${file.extension}"},`;
    return acc;
  }, "");

  const query = `
    mutation {
      ServiceAddFilesToRide(rideId:"${rideId}", files: [${filesFormated}]) ${rideQuery}
    }
  `;

  try {
    const { ServiceAddFilesToRide } = yield graphQLClient().request(query);
    yield put(
      OngoingRidesActions.onAddFilesToRideOngoingSuccess(ServiceAddFilesToRide)
    );
    if (_.isFunction(callback)) callback(null, files);
  } catch (error) {
    // const { message = "" } = error.response.errors[0];
    // if (_.isFunction(callback)) callback({ error: message });
    yield errorHandler(
      error,
      OngoingRidesActions.onAddFilesToRideOngoingFailure
    );
  }
}

function* onDropFilesToRideOngoingAsyncRequest({
  rideId,
  files: paths = [],
  callback,
}) {
  // list of paths, (string)

  // join an path array to string
  const filesPathsFormated = paths.reduce((acc, path) => {
    acc += `"${path}",`;
    return acc;
  }, "");
  const query = `
    mutation {
      ServiceDropFilesToRide(rideId:"${rideId}", filesPath: [${filesPathsFormated}]) {
        _id
        files { path name extension size }
      }
    }
  `;

  try {
    const { ServiceDropFilesToRide } = yield graphQLClient().request(query);
    yield put(
      OngoingRidesActions.onDropFilesToRideOngoingSuccess(
        ServiceDropFilesToRide
      )
    );

    if (_.isFunction(callback)) callback(null);
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    if (_.isFunction(callback)) callback({ error: message });
    yield errorHandler(
      message,
      OngoingRidesActions.onDropFilesToRideOngoingFailure
    );
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    OngoingRideTypes.SET_PATIENT_STATUS_REQUEST,
    setPatientStatusAsyncRequest
  ),
  takeLatest(OngoingRideTypes.ON_GOING_RIDES_REQUEST, onGoingRidesAsyncRequest),
  takeLatest(OngoingRideTypes.CANCEL_RIDE_REQUEST, cancelRideAsyncRequest),
  takeLatest(
    OngoingRideTypes.ON_REFRESH_DISPATCH_RIDE_REQUEST,
    onRefreshDispatchRideAsyncRequest
  ),
  takeLatest(
    OngoingRideTypes.ON_ADD_FILES_TO_RIDE_ONGOING_REQUEST,
    onAddFilesToRideAsyncRequest
  ),
  takeLatest(
    OngoingRideTypes.ON_DROP_FILES_TO_RIDE_ONGOING_REQUEST,
    onDropFilesToRideOngoingAsyncRequest
  ),
];
